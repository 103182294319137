import store from '@/store';
import { queryTool, executeTool, queryTool2, executeTool2 } from '../base/tool';

const contractAddress = store.state.config[308].val;
const abi = require('./abi.json');
const contractObj = {
    contractAbi: abi,
    contractAddress: contractAddress,
};

//累计算力
export const totalInPower = ({ address = store.state.address } = {}) => {
    return queryTool2({
        name: '累计算力',
        ...contractObj,
        fn: 'totalInPower',
        params: [address],
    });
};


/**
 * 入单
 **/
export const BuyOrder = (amount,nowpower,orderid) => {
    console.log('orderid',orderid)
    return executeTool({
        name: '入单',
        ...contractObj,
        fn: 'BuyOrder',
        params: [amount,nowpower,orderid],
    });
};
