<template>
    <div class="all-medal-content2 flex-column">
        <div class="grid-container" :class="[screenWidth > 1000 ? '' : 'grid-container2']">
            <div class="box2 item2" :class="[screenWidth > 1000 ? '' : 'item22']">
                <img src="../../assets/img/team/selfyj.png" alt="" />
                <p>
                    <!-- <span v-if="creditTotal[0] && creditTotal[0]['tradeType']">{{ TradeType[creditTotal[0]['tradeType']] }}</span> -->
                    <span>{{ lang == 'en' ? 'Daily output' : '每日产出' }}</span>
                    <span v-if="creditTotal[61]">
                        {{ creditTotal[61].amount }}
                        <em>{{ coinConfig[creditTotal[62].rewardcoinid].coinname }}</em>
                    </span>
                    <span v-else>
                        0
                        <em>AJPLUS</em>
                    </span>
                </p>
            </div>
            <div class="box2 item2" :class="[screenWidth > 1000 ? '' : 'item22']">
                <img src="../../assets/img/team/dqyj.png" alt="" />
                <p>
                    <!-- <span v-if="creditTotal[1] && creditTotal[1]['tradeType']">{{ TradeType[creditTotal[1]['tradeType']] }}</span> -->
                    <span>{{ lang == 'en' ? 'Sales Awards' : '销售奖' }}</span>
                    <span v-if="creditTotal[62]">
                        {{ creditTotal[62].amount }}
                        <em>{{ coinConfig[creditTotal[62].rewardcoinid].coinname }}</em>
                    </span>
                    <span v-else>
                        0
                        <em>USDT</em>
                    </span>
                </p>
            </div>
            <div class="box2 item2" :class="[screenWidth > 1000 ? '' : 'item22']">
                <img src="../../assets/img/team/xqyj.png" alt="" />
                <p>
                    <!-- <span v-if="creditTotal[2] && creditTotal[2]['tradeType']">{{ TradeType[creditTotal[2]['tradeType']] }}</span> -->
                    <span>{{ lang == 'en' ? 'Team Earnings Award' : '团队收益奖' }}</span>
                    <span v-if="creditTotal[63]">
                        {{ creditTotal[63].amount }}
                        <em>{{ coinConfig[creditTotal[63].rewardcoinid].coinname }}</em>
                    </span>
                    <span v-else>
                        0
                        <em>AJPLUS</em>
                    </span>
                </p>
            </div>
            <div class="box2 item2" :class="[screenWidth > 1000 ? '' : 'item22']">
                <img src="../../assets/img/team/selfyj.png" alt="" />
                <p>
                    <!-- <span v-if="creditTotal[3] && creditTotal[3]['tradeType']">{{ TradeType[creditTotal[3]['tradeType']] }}</span> -->
                    <span>{{ lang == 'en' ? 'Team Performance Award' : '团队业绩奖' }}</span>
                    <span v-if="creditTotal[64]">
                        {{ creditTotal[64].amount }}
                        <em>{{ coinConfig[creditTotal[64].rewardcoinid].coinname }}</em>
                    </span>
                    <span v-else>
                        0
                        <em>USDT</em>
                    </span>
                </p>
            </div>
        </div>
        <div class="flex-1 teamList">
            <div class="flex tabBox">
                <p class="flex" v-for="item in CredittypeArr" @click="changeTypeFn(item)">
                    <span :class="{ active: item == Credittype, 'mr-4': screenWidth < 1000, span2: screenWidth < 1000 }">{{ TradeType[item] }}</span>
                </p>
            </div>
            <div class="flex-between" v-if="totalCount">
                <span>{{ lang == 'en' ? 'Total' : '总共' }}{{ totalCount }}{{ lang == 'en' ? 'data' : '条数据' }}</span>
                <custom-pagination v-if="totalCount" :totalItems="totalCount" :itemsPerPage="pageSize" @page-change="onChange" />
            </div>

            <ul v-if="arrList.length > 0" class="grid-container3" :class="[screenWidth > 900 ? 'grid-container3' : 'mb12']">
                <li class="box3" v-for="item in arrList">
                    <div class="flex teamTitle">
                        <p class="flex-1">
                            <span>{{ item.inday }}</span>
                        </p>
                        <span>
                            {{ lang == 'en' ? 'Award Amount:' : '奖励金额：' }}{{ item.amount }}
                            <em>{{ coinConfig[item.rewardcoinid]['coinname'] }}</em>
                        </span>
                    </div>
                    <p class="rewardDetail" @click="getRewardDayDetailFn(item)">
                        <span>{{ lang == 'en' ? 'Detail' : '明细' }}>></span>
                        <span v-if="(Number(item.jsstate) == 0)">{{ lang == 'en' ? 'To be issued' : '待发放' }}</span>
                        <span v-if="(Number(item.jsstate) == 1)">{{ lang == 'en' ? 'Issued' : '已发放' }}</span>
                    </p>
                </li>
            </ul>
            <empty :data="arrList" />
        </div>
        <a-modal class="detailBox" :footer="false" :title="`${TradeType[Credittype]}-${indayTime}`" :visible="visible" @cancel="handleCancel">
            <ul class="dayDetail-list">
                <li v-for="item in dayRewardDetailArr">
                    <p class="flex-between dayDetail_title">
                        <span>{{ lang == 'en' ? 'Amount:' : '金额：' }}{{ item.rewardAmount }} {{ coinConfig[item.rewardCoinid]['coinname'] }}</span>
                        <span v-if="screenWidth > 800">{{ lang == 'en' ? 'Order:' : '订单：' }}{{ item.orderCode }}</span>
                    </p>
                    <p class="dayDetail_des">
                        <span v-if="screenWidth < 800">{{ lang == 'en' ? 'Order:' : '订单：' }}{{ item.orderCode }}</span>
                        <span>{{ lang == 'en' ? 'Summary:' : '摘要：' }}{{ item.content }}</span>
                        <span>{{ lang == 'en' ? 'Bonus Time:' : '奖励时间：' }}{{ item.rewardTime.replace('T', ' ') }}</span>
                    </p>
                </li>
            </ul>
        </a-modal>
    </div>
</template>
<script>
import CustomPagination from '@/components/CustomPagination';
import empty from '@/components/empty';
import { mapState } from 'vuex';
import { creditTotal, creditDay, creditDetail } from '@/api/Trade.js';
export default {
    data() {
        return {
            creditTotal: {},
            arrList: [],
            pageIndex: 1,
            pageSize: 5,
            totalCount: 0,
            isLoading: false,
            CredittypeArr: [31, 51, 52, 53],
            Credittype: 31,
            dayRewardDetailArr: [],

            pageIndex_Detail: 1,
            pageSize_Detail: 100,
            totalCount_Detail: 0,
            visible: false,
            indayTime: '',
            bodyStyle: {},
        };
    },
    components: { empty, CustomPagination },
    filters: {
        addressF(v) {
            if (!v) {
                return '-';
            }
            return v.replace(/^([A-Za-z0-9]{5})[A-Za-z0-9]{33}([A-Za-z0-9]{4})$/, '$1…$2');
        },
    },
    computed: {
        ...mapState(['screenWidth', 'address', 'enum', 'coinConfig', 'lang']),
        TradeType() {
            if (this.lang == 'en') {
                return {
                    0: 'All', // 全部
                    1: 'Recharge', // 充值
                    2: 'Withdraw', // 提现
                    3: 'Roll out', // 转出
                    4: 'Into', // 转入
                    6: 'System top-up', // 系统充值
                    7: 'Systematic deductions', //系统扣款
                    8: 'The transaction is sold', //交易售出
                    9: 'Deals', // 交易获得
                    31: 'Daily output', // 每日产出
                    51: 'Sales Awards', // 销售
                    52: 'Team Earnings Award', // 团队收益奖
                    53: 'Team Performance Award', // 团队业绩奖
                };
            } else {
                return this.enum['TradeType'];
            }
        },
    },

    async created() {},
    async mounted() {
        this.init();
    },
    watch: {},
    methods: {
        changeTypeFn(item) {
            if (item == this.Credittype) return;
            this.Credittype = item;
            this.pageIndex = 1;
            this.creditDayFn();
        },
        async onChange(e) {
            this.pageIndex = e;
            this.creditDayFn();
        },
        async init() {
            let res = (await creditTotal()).data;
            let a = {};
            res.forEach(element => {
                a[element.tradeType] = element;
            });
            this.creditTotal = a
            this.creditDayFn();
        },
        async creditDayFn(item) {
            this.isLoading = true;
            let res = await creditDay({
                Credittype: this.Credittype,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            });
            this.totalCount = res.totalCount;
            this.arrList = [];
            this.arrList = res.list;
            this.isLoading = false;
        },
        // 奖励明细记录
        async getRewardDayDetailFn(item) {
            console.log('item',item)
            this.indayTime = item.inday;
            let res = await creditDetail({
                Credittype: this.Credittype,
                stime: item.inday,
                etime: item.inday,
                pageIndex: this.pageIndex_Detail,
                pageSize: this.pageSize_Detail,
            });
            this.dayRewardDetailArr = res.list;
            this.totalCount_Detail = res.totalCount;
            this.visible = true;
        },
        handleCancel(e) {
            this.visible = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.all-medal-content2 {
    width: 100%;
    flex: 1;
    display: flex;
    margin: 0px 0px !important;
}
.box3 {
    background: #1a1a1d;
    border-radius: 15px;
    padding: 16px 20px;
    & > div:nth-of-type(1) {
        border-bottom: 1px solid #313131;
    }
}
.mb12 {
    row-gap: 12px !important;
}
.box2 {
    height: 100px;
    background: linear-gradient(90deg, #041cde 0%, #4b7bf8 100%);
    border-radius: 20px;
}
.grid-container2 {
    row-gap: 12px !important;
    grid-template-columns: repeat(1, 1fr) !important;
}
.grid-container3 {
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    .item22{
        height: 80px !important;
        padding: 10px 16px !important;
        img {
            width: 48px !important;
            height: 48px !important;
        }
    }
    .item2 {
        display: flex;
        align-items: center;
        padding: 16px 20px;
        img {
            width: 50px;
            height: 50px;
        }
        p {
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            margin-bottom: 0px !important;
            & > span:nth-of-type(1) {
                font-size: 14px;
                color: rgba($color: #fff, $alpha: 0.8);
            }
            & > span:nth-of-type(2) {
                font-size: 20px;
            }
            em {
                font-style: normal;
                font-size: 13px;
            }
        }
    }
}
.teamList {
    margin-top: 30px;
    & > div:nth-of-type(1) {
        padding-bottom: 20px;
        & > p {
            margin-bottom: 0px !important;
            img {
            }
            span {
                padding: 8px 12px;
                background-color: rgba(255, 255, 255, 0.1);
                font-size: 16px;
                color: #fff;
                border-radius: 12px;
                margin-right: 16px;
                cursor: pointer;
            }
            .span2 {
                font-size: 14px !important;
            }
            span.active {
                background-color: #041cde !important;
                color: #fff;
            }
        }
    }
}
.grid-container4 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.teamItem {
    padding-top: 20px;
    & > p {
        margin-bottom: 0px !important;
        img {
            width: 42px;
            height: 42px;
        }
        & > span {
            padding-left: 12px;
            display: flex;
            flex-direction: column;
            & > span:nth-of-type(1) {
                font-size: 14px;
                color: rgba($color: #fff, $alpha: 0.8);
            }
            & > span:nth-of-type(2) {
                font-size: 20px;
            }
        }
    }
}
.teamTitle {
    & > p {
        font-size: 16px;
        margin-bottom: 10px;
    }
    & > span {
        margin-bottom: 10px;
        font-size: 16px;
        em {
            font-size: 14px;
            font-style: normal;
        }
    }
}
.ai-center {
    align-items: center;
}
.paginationBox {
    .ant-pagination-simple-pager {
        color: #fff;
    }
}
.rewardDetail {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    span {
        padding: 4px 12px 4px 0px;
        // border: 1px solid rgba($color: #FFF, $alpha: 0.8);
        border-radius: 4px;
        font-size: 14px;
        color: rgba($color: #fff, $alpha: 0.8);
        cursor: pointer;
    }
}
.detailBox {
    ::v-deep .ant-modal {
        width: 800px !important;
        max-width: 90%;
    }
    ::v-deep .ant-modal-header {
        background-color: #232323 !important;
        border-bottom: 1px solid #3d3d3d !important;
        .ant-modal-title {
            color: #fff;
        }
    }
    ::v-deep .ant-modal-content {
        border-bottom: 1px solid #3d3d3d !important;
        .ant-modal-close-x {
            color: #fff !important;
        }
    }
    ::v-deep .ant-modal-body {
        background: #232323;
        color: #fff;
        height: 600px;
        overflow: auto;
        padding: 20px !important;
        // 滚动条整体部分
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: #adadad;
        }

        &::-webkit-scrollbar-track,
        ::-webkit-scrollbar-thumb {
            border-radius: 999px;
            border: 0px solid transparent;
        }

        &::-webkit-scrollbar-track {
            box-shadow: 1px 1px 5px rgba(163, 163, 163, 0.4) inset;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            min-height: 20px;
            background-clip: content-box;
            box-shadow: 0 0 0 5px rgba(76, 82, 87, 0.37) inset;
        }

        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
}
.dayDetail-list {
    li {
        background-color: #1a1a1d;
        padding: 16px 20px;
        border-radius: 10px;
        margin-bottom: 16px;
    }
    p {
        margin-bottom: 0px !important;
    }
    .dayDetail_title {
        padding-bottom: 10px;
        font-size: 16px;
    }
    .dayDetail_des {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        color: rgba($color: #fff, $alpha: 0.8);
    }
}
</style>
